import React from 'react'
import SbEditable from 'storyblok-react'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { TextSm, MarkdownHandler } from '@system'
import font from '@helpers/font'

const { makeResponsiveFontSizes } = font

const useStyles = makeStyles((theme) => ({
  region: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightBold,
    display: 'block',
  },
  numberText: {
    color: theme.palette.text.secondary,
    display: 'block',
    lineHeight: 1.75,
    ...makeResponsiveFontSizes(16, 11),
  },
}))

const ContactNumber = (props) => {
  const classes = useStyles()
  return (
    <SbEditable content={props.blok}>
      <Box marginBottom="24px">
        <TextSm className={classes.region}>{props.blok.region}</TextSm>
        <MarkdownHandler className={classes.numberText}>
          {props.blok.numberText}
        </MarkdownHandler>
      </Box>
    </SbEditable>
  )
}

export default ContactNumber
